<!-- 登录 -->
<template lang="">
  <div class="login">
    <div class="app-haeder">
      <van-nav-bar title="" />
    </div>
    <div class="loginimg">
      <img
        src="https://lesson.iapeap.com/images/EmotionalValue/loginimg.png"
        alt=""
      />
    </div>

    <div class="from">
      <div class="from-title">欢迎来到</div>
      <div class="headline-fk"></div>
      <div class="from-title2">{{ company }}</div>

      <van-field
        v-model="userName"
        name="账号"
        label="账号"
        placeholder="账号"
        :rules="[{ required: true, message: '账号' }]"
      />

      <van-field
        v-model="passWord"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />

      <div class="fromlogin">
        <button class="fromlogin-button" @click="onSubmit">登录</button>
      </div>
      <div class="register" @click="goregister">立即注册</div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { login } from "../../api/login";
export default {
  data() {
    return {
      userName: "",
      passWord: "",
      unitId: window.localStorage.getItem("unitId"), //公司id
      company: "", //公司
    };
  },

  methods: {
    onSubmit() {
      Toast.loading({ message: "正在登录", forbidClick: true });
      let data = {
        userName: this.userName,
        passWord: this.passWord,
      };
      if (this.userName && this.passWord) {
        login(data).then((res) => {
          if (res.ID) {
            this.userId = res.ID;
            window.localStorage.setItem("userId", this.userId);
            Toast.success("登录成功");
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          }

          if (res.msg && res.msg == "该用户不存在，请联系管理员添加！") {
            Toast.fail("该用户不存在");
          }
          if (res.msg && res.msg == "密码错误！") {
            Toast.fail("密码错误！");
          }
        });
      } else {
        Toast.fail("未填账号信息");
      }
    },
    goregister() {
      this.$router.push("/register");
    },
  },

  mounted() {
    if (this.unitId == "undefined") {
      this.company = "高情商训练营";
    }
    if (this.unitId == "sys1818") {
      this.company = "情绪价值训练营";
    }
    if (this.unitId == "1397") {
      this.company = "高情商训练营";
    }
  },
};
</script>
<style lang="less">
.login {
  .loginimg {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 131px;
      height: 94px;
    }
  }

  .from {
    height: 428px;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    margin: 0 20px;
    box-shadow: 5px #333;
    position: relative;
    bottom: 4px;
    padding: 35px 30px 72px 30px;
    box-sizing: border-box;
  }

  .from-title {
    color: rgba(0, 0, 37, 1);
    font-size: 24px;
    line-height: 33px;
    position: relative;
    z-index: 1;
  }

  .from-title2 {
    color: rgba(0, 0, 37, 1);
    font-size: 24px;
    line-height: 33px;
    margin-top: -4px;
    margin-bottom: 50px;
  }

  .headline-fk {
    width: 64px;
    height: 5px;
    background: rgb(104, 154, 217);
    border-radius: 1px;
    position: relative;
    right: 5px;
    bottom: 9px;
    z-index: 0;
    transform: skew(-25deg);
  }

  .from-button {
    background-color: #fff;
    border: 0;
    color: rgb(114, 172, 244);
  }

  .van-cell {
    padding: 0.1rem 0rem;
    border: 0;
  }

  .van-field__body {
    border-radius: 8px;
    height: 28px;
    border: 1px solid rgba(224, 224, 224, 1);
    height: 28px;
  }

  .van-field__control {
    width: 130px;
  }

  .fromlogin {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    .fromlogin-button {
      background-color: rgba(114, 172, 244, 1);
      border-radius: 100px;
      padding: 5px 73px 6px 73px;
      border: 0;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
    }
  }

  .van-field__label {
    width: 45px;
    line-height: 32px;
  }

  .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0;
  }

  .van-cell::after {
    border-bottom: 0;
  }
  .moren {
    color: #bbbaba;
    font-size: 13px;
    text-align: right;
  }
  .register {
    margin-top: 20px;
    text-align: center;
    color: #7e7d7d;
  }
}
</style>
